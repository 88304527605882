import React from 'react';
import Code from './assets/img/code.jpg';
import { ReactComponent as Facebook } from './assets/img/facebook.svg';
import { ReactComponent as Instagram } from './assets/img/instagram.svg';
import { ReactComponent as Email } from './assets/img/email.svg';

import './App.css';
import './footer.css';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';


//import ReactGA from 'react-ga';

const styles = theme => ({

  isSmartPhoneOrTable: {
    display: 'block',
    '@media(min-width: 768px)': {
      display: 'none',
    }
  },
  isBigScreen: {
    display: 'grid',
    marginTop: '-18rem',
    marginLeft: '1rem',
  },
  isMinScreen: {
    display: 'grid',
    fontSize: '140px',
    marginTop: '-15rem',
    marginLeft: '2rem',
    '@media(min-width: 768px)': {
      display: 'none',
    },

    shieldcontainer:{

    },

    rectangulo: {
      width: '100%',
      height: '50px',
    }

  }


});

const App = (props) => {
  const { classes } = props;
  /*ReactGA.initialize('G-W0JR3PV2QZ');*/
  return (
    <>
      <header>
        { /*<img src={Code} className="App-logo" alt="logo" />*/}
      </header>
      
      <content className="bodyvidrio vidrio" style={{ backgroundImage: `url(${Code})` }}>
      { /* <div className="bigotes">&#x2774;</div>*/}
        <div className="titulo">
        { /* &#x23de; */}

        
        <div className="rectangulo"></div> 
       
          <div className="shieldcontainer"> <div className="container__star"></div>
          <div className={classNames(classes.isBigScreen)}> devChile</div>
          { /*<div className={classNames(classes.isMinScreen)}> devChile</div>*/}
          </div>
          { /* &#x23df;*/}
          
          
        </div>
        { /*<div className="bigotes">&#x2775;</div>*/}
      </content>
      
      <footer className="flex-rw">
        <ul className="footer-list-top">
        </ul>

        <section className="footer-social-section flex-rw">
          <span className="footer-social-overlap footer-social-connect">
            CONECTA
          </span>
          <span className="footer-social-overlap footer-social-icons-wrapper">
            <i className="fa fa-pinterest"></i>
            <i className="fa fa-facebook"> <a href = "mailto: developer@devchile.cl"><Email style={{ with: '80px', height: '50px' }} /></a> </i>
            <i className="fa fa-twitter"></i>
            <i className="fa fa-youtube"></i>
            <i className="fa fa-google-plus"></i>
          </span>
        </section>
        <section className="footer-bottom-section flex-rw">
          <div className="footer-bottom-wrapper">
            <i className="fa fa-copyright" >
            </i> 2021 devChile <address className="footer-address" ></address><span className="footer-bottom-rights"> </span>
          </div>

        </section>
      </footer>


    </>
  );
}

export default withStyles(styles)(App);
